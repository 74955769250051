.sectionPartnership {
  background: url('../content/images/buildingBg.jpg') no-repeat center;
  background-size: cover;
  color: $white;
  @media #{$maxW-750} {
    text-align: left;
  }
  &__list {
    margin-top: 3.89rem;
  }
  &__item:not(:first-child) {
    margin-top: 1.39rem;
  }
  &__desc {
    @include ralewayBold;
    margin-top: 3rem;
    font-size: 22px;
  }
  .btn {
    margin-top: 3.33rem;
  }
}
