.row::after {
  content: "";
  display: table;
  clear: both;
  width: 100%;
}

[class*='col-'] {
  padding-right: 30px;
  float: left;
}

[class*='col-']:last-child {
  padding-right: 0;
}

/* COLUMNS GENERATOR DEFAULT */
@include columns("");

/* COLUMNS GENERATOR RESPONSIVE */
// 750 px
@media #{$maxW-750} {
  @include columns("-pl");
}
