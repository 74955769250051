/* TEXT */
.u-bold {
  font-weight: bold;
}

// COLORS
.u-clrWhite {
  color: $white;
}

.u-clrGrey {
  color: $grey;
}

.u-clrLightBlue {
  color: $lightBlue;
}

.red {
  color: #d65353;
}

.green {
  color: #00bd8e;
}

// POSITIONING
.u-posRel {
  position: relative;
}

.u-tac {
  text-align: center;
}
