.roller__arrow {
  position: relative;
  cursor: pointer;
  &::before {
    @include size(20px, 12px);
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    content: '';
    background: url('../content/icons/svg/vee.svg') no-repeat center;
    background-size: contain;
  }
  &+ * {
      transform: scale(1, 0) translate3d(0, 0, 0);
      transform-origin: top;
      overflow: hidden;
      transition: all .3s linear;
  }
}
.roller__arrow.active {
  &::before {
    transform: scaleY(-1);
  }
  &+ * {
    transform: scale(1, 1) translate3d(0, 0, 0);
    transform-origin: top;
    overflow: visible;
  }
}

.navigation__list.active {
  max-height: 500px;
  overflow: visible;
  transform: scaleY(1) translate3d(0, 0, 0);
  transition: all .3s linear;
  transform-origin: top;
}
