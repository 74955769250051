.navigation {
  margin-bottom: 2.78rem;
  padding-top: 20px;
  position: relative;
  @media #{$maxW-750} {
    width: 100%;
    position: relative;
    top: 0;
    background-color: $white; 
    z-index: 999;
  }
  &__logo {
    float: left;
  }
  &__list {
    @include ralewayLight;
    display: inline-block;
    float: right;
    list-style: none;
    @media #{$maxW-750} {
      width: 100%;
      max-height: 0;
      overflow: hidden;
      transform: scaleY(0) translate3d(0, 0 ,0);
      transform-origin: top;
      transition: all .3s linear;
      clear: both;
    }
  }
  &__item {
    display: inline-block;
    padding: 0 1.39em 0;
    line-height: 35px;
    @media #{$maxW-750} {
      width: 100%;
      display: block;
      padding: 10px 0 0;
    }
    &--flag {
      display: inline-block;
      vertical-align: middle;
    }
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
    &.active > * {
      @include ralewaySemibold;
    }
  }
  &__link {
    color: $darkGrey;
    text-decoration: none;
  }
}

.collapse {
  display: none;
  float: right;
  top: 0;
  right: 0;
  cursor: pointer;
  &__line {
    @include size(40px, 3px);
    margin-top: 9px;
    display: block;
    background-color: $lightBlue;
  }
  @media #{$maxW-750} {
    display: block;
  }
}
