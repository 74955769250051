.sectionSkewed {
  height: 1510px;
  padding-bottom: 0;
  background: url('../content/images/grey_blue_skew.jpg') no-repeat center;
  background-size: cover;
  overflow: hidden;
  color: white;
  @media #{$maxW-750} {
    height: auto;
    padding: 0;
    background: none;
  }
  &__wrapper {
    height: 100%;
    position: relative;
  }
  &__top,
  &__bottom {
    position: relative;
  }
  &__top {
    padding-bottom: 95px;
    &::after {
      @media #{$maxW-750} {
        @include size(240px, 270px);
        max-width: 40%;
        content: '';
        position: absolute;
        bottom: -19%;
        right: -10%;
        background: url('../content/images/juice.png') no-repeat center;
        background-size: contain;
        z-index: 3;
      }
      @media #{$maxW-460} {
        bottom: -13%;
      }
    }
    @media #{$maxW-750} {
      padding-top: 100px;
      background-color: $darkGrey;
      text-align: left;
    }
  }
  &__bottom {
    padding-top: 150px;
    text-align: right;
    @media #{$maxW-750} {
      background-color: $lightBlue;
      padding: 100px 0;
      text-align: left;
    }
    h2 {
      padding-left: 30%;
      @media #{$maxW-750} {
        max-width: 70%;
        padding-left: 0;
      }
      @media #{$maxW-460} {
        max-width: 100%;
      }
    }
  }
  &__list {
    margin-top: 50px;
  }
  &__rightImg {
    right: 0;
    bottom: -30%;
  }
  &__leftImg {
    left: 0;
    bottom: 0;
  }
  &__rightImg,
  &__leftImg {
    position: absolute;
    max-width: 50%;
    @media #{$maxW-750} {
      display: none;
    }
  }
}
