// STRUCTURE */
* {
  box-sizing: border-box;
}

html {
  font-size: 18px;
  line-height: normal;
  overflow-x: hidden;
}

body {
  width: 100%;
  margin: auto;
  color: $grey;
  line-height: normal;
  @media #{$maxW-750} {
    text-align: center;
  }
}

section {
  width: 100%;
}

.container {
  width: 1200px;
  max-width: 95%;
  margin: auto;
}

.pageContent {
  margin-top: 60px;
}

// TEXT */
h1,
h2,
h3,
h4,
h5,
h6 {
  @include ralewayExtraBold;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 38px;
  line-height: 48px;
}

h3 {
  font-size: 35px;
}

h4 {
  font-size: 32px;
}

h5 {
  font-size: 29px;
}

h6 {
  font-size: 25px;
}

p, a {
  @include ralewayRegular;
}

small {
  @include ralewayLight;
}

b {
    @include ralewayBold;
}

q {
  @include ralewayItalic;
}

article p {
  line-height: 30px;
  @media #{$maxW-750} {
    font-size: normal;
  }
}

a {
  text-decoration: underline;
  color: $lightBlue;
  cursor: pointer;
}

a.btn {
  text-decoration: none;
}

li.styledList {
  @include ralewayRegular;
  padding-left: 1.67rem;
  position: relative;
  &::before {
    @include size(10px, 10px);
    content: '';
    border-radius: 100%;
    display: inline-block;
    position: absolute;
    left: 0;
    transform: translateY(50%);
  }
  &:not(:first-child) {
    margin-top: 1.11rem;
  }
  &.inverted {
    padding-left: 0;
    padding-right: 1.67rem;
    &::before {
      right: 0;
      left: auto;
    }
  }
  &.inverted {
    @media #{$maxW-750} {
      padding-right: 0;
      padding-left: 1.67rem;
    }
  }
  &.inverted::before {
    @media #{$maxW-750} {
      right: auto;
      left: 0;
    }
  }
}

.list {
  &__superLightBlue::before {
    background-color: $superLightBlue;
  }
  &__lightBlue::before {
    background-color: $lightBlue;
  }
  &__white::before {
    background-color: $white;
  }
}

.pageHeader {
  margin-top: 2.78rem;
  color: $lightBlue;
}

.sectionHeader {
  margin-bottom: 2.22rem;
  @media #{$maxW-750} {
    margin-bottom: 50px;
  }
}

.sectionSubtitle {
  @include ralewayLight;
  margin-top: 2.22rem;
  font-size: 23px;
  @media #{$maxW-750} {
    margin-top: 50px;
  }
}

.sectionDescription {
  @include ralewayBold;
  font-size: 22px;
}

// TABLES */

table {
  @include ralewayRegular;
  width: 100%;
  border-collapse: separate;
}

table * {
  @media #{$maxW-1024} {
    font-size: 12px;
  }
}

tbody {
  padding: 0 10px 10px;
}

th {
  @include ralewaySemibold;
  background-color: $darkGrey;
  text-align: center;
  color: $white;
  &:first-child {
    border-top-left-radius: 4px;
  }
  &:last-child {
    border-top-right-radius: 4px;
  }
}

tr {
  border-radius: 0;
  transition: background-color .3s linear;
  &:hover {
    background-color: lighten($lightBlue, 30%);
  }
}

td {
  max-width: 25%;
  border-bottom: 1px dotted $darkGrey;
  border-radius: 0;
  white-space: pre;
  &:nth-child(n+3) {
    text-align: center;
  }
}

th,
td {
  padding: 10px 0 10px 20px;
  vertical-align: middle;
}

// OTHER */
.btn {
  cursor: pointer;
}
