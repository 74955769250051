.sectionOptions {
  &__grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    -ms-flex-negative: 1;
  }
  &__wrapper {
    flex: 0 1 33.333%;
    margin-top: 95px;
    &:nth-of-type(3n) {
      padding-right: 0;
    }
    &:first-child {
      @media #{$maxW-750} {
        margin-top: 95px;
      }
    }
    @media #{$maxW-750} {
      margin-top: 60px;
      padding-right: 0;
      flex: 0 1 60%;
      text-align: left;
    }
    @media #{$maxW-460} {
      flex: 0 1 95%;
    }
  }
  &__icon {
    @include size(60px, 55px);
    display: inline-block;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  &__desc {
    @include ralewayMedium;
    width: calc(100% - 65px);
    padding-left: 30px;
    padding-right: 50px;
    display: inline-block;
    vertical-align: top;
  }
}
