@mixin size($width, $height) {
  width: $width;
  height: $height;
}

@mixin clearfix {
  content: "";
  display: table;
  clear: both;
}

@mixin columns($type) {
  $columns: 12;
  $column-width: 8.3333%;
  @for $i from 1 through $columns {
    .col#{$type}-#{$i} {
      @if $i == $columns {
        width: round($column-width * $i);
      } @else {
        width: $column-width * $i;
      }
    }
  }
}
