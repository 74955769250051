// SWIPER CUSTOM STYLES */
.swiper-pagination-bullet {
  @include size(14px, 14px);
  margin-right: 15px;
  opacity: 1;
  background: $superLightBlue;
  &-active {
    background: $lightBlue;
    box-shadow: 0 .5px 0 5px $superLightBlue;
  }
}

// TOP SLIDER */
.hpSlider {
  min-height: 385px;
  padding-bottom: 75px;
  position: relative;
  overflow: visible;
  &__slideTitle {
    padding-top: 3.2rem;
    @media #{$maxW-750} {
      padding-top: 0;
    }
  }
  &__slideText {
    @include ralewayLight;
    max-width: 60%;
    margin-top: 1.67rem;
    font-size: 22px;
    line-height: 30px;
    @media #{$maxW-750} {
      max-width: 100%;
    }
  }
  &__slideSubtitle {
    max-width: 60%;
    margin-top: 3.34rem;
    @media #{$maxW-750} {
      margin-top: 50px;
      max-width: 100%;
    }
  }
  &__slideImage {
    position: absolute;
    bottom: -40px;
    right: 0;
    @media #{$maxW-1200} {
      max-width: 40%;
    }
    @media #{$maxW-750} {
      display: none;
    }
  }
  // SWIPER
  .swiper-wrapper {
    padding-bottom: 30px;
  }
  .swiper-pagination-bullets {
    width: auto;
    display: inline-block;
    position: static;
  }
  .swiper-hpSlider {
    padding-bottom: 3px;
  }
}

// USER REVIEW SLIDER */
.sectionUserReview {
  text-align: center;
  &__wrapper {
    margin-top: 4.44rem;
  }
  &__slider {

  }
  &__slide {
    max-width: 720px;
    margin: auto;
    @media #{$maxW-900} {
      max-width: 80%;
    }
  }
  &__quote {
    font-size: 22px;
    line-height: 35px;
  }
  &__name {
    margin-top: 3.33rem;
    font-size: 23px;
    line-height: 34px;
    color: $lightBlue;
  }
  &__position {
    line-height: 34px;
    color: $lightBlue;
  }
  &__logo {
    margin: auto;
    display: table;
  }
}
// SWIPER
.sectionUserReview__slider {
  .swiper-pagination {
    display: none;
    @media #{$maxW-750} {
      display: block;
      position: relative;
      margin-top: 30px;
    }
  }
  [class*="swiper-button"] {
    @media #{$maxW-750} {
      display: none;
    }
  }
}
