// COLORS */
$white: #fff;
$black: #000;
$grey: #3c414f;
$darkGrey: #3b414f;
$lightGrey: #efeff4;
$lightBlue: #4385ee;
$superLightBlue: #afcbf8;

// MEDIA QUERIES */
$maxW-1200: 'only screen and (max-width: 1200px)';
$maxW-1024: 'only screen and (max-width: 1024px)';
$maxW-900: 'only screen and (max-width: 900px)';
$maxW-750: 'only screen and (max-width: 750px)';
$maxW-460: 'only screen and (max-width: 460px)';
$maxW-320: 'only screen and (max-width: 320px)';
