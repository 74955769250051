.sectionAccess {
  background-color: $lightGrey;
  @media #{$maxW-750} {
    background-color: $darkGrey;
    color: $white;
  }
  &__header {
    margin-bottom: 70px;
  }
  &__contentWrapper {
    display: table;
    margin: auto;
    position: relative;
  }
  &__bg {
    @media #{$maxW-750} {
      display: none;
    }
  }
  &__content {
    max-width: 375px;
    position: absolute;
    top: 140px;
    left: 190px;
    color: $white;
    @media #{$maxW-750} {
      position: static;
    }
  }
  &__phoneIco {
    @include size(40px, auto);
  }
  &__title {
    margin-top: 1.67rem;
    font-weight: bold;
  }
  &__subtitle {
    margin-top: 1.67rem;
  }
  &__image {
    max-width: 100%;
    margin: 100px auto 0;
    display: none;
    @media #{$maxW-750} {
      display: table;
    }
  }
  .btn {
    margin-top: 2.78rem;
    font-weight: bold;
  }
}
