.popup {
  @include size(100%, 100%);
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(60, 65, 79, .9);
  text-align: left;
  color: $darkGrey;
  z-index: 99;
  @media #{$maxW-750} {
    height: calc(100% - 75px);
    top: 75px;
  }
  &__box {
    width: 840px;
    max-width: 95%;
    max-height: 95%;
    padding: 4.44em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 3px;
    background-color: $white;
    @media #{$maxW-750} {
      overflow-y: scroll;
    }
    @media #{$maxW-460} {
      padding: 4rem 1rem 1rem;
    }
  }
  &__close {
    @include size(25px, 25px);
    position: absolute;
    top: 30px;
    right: 30px;
    background: url('../content/icons/svg/close.svg') no-repeat;
    background-size: contain;
    cursor: pointer;
  }
  &__overflow {

  }
  &__content {
    max-height: 75vh;
  }
  &__ico {

  }
  &__heading {
    margin-top: 1.95em;
    font-size: 22px;
    color: $lightBlue;
  }
  &__list {
    margin-top: 3.35em;
  }
  &__item {
    &:last-child {
      margin-bottom: 30px;
    }
  }

  // TABULKOVY POPUP */
  &-table {
    .popup__box {
      width: 1200px;
    }
    .popup__overflow table {
      padding-top: 50px;
    }
    .popup__content {
      @media #{$maxW-460} {
        overflow-x: scroll;
      }
    }
  }
}
