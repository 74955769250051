.sectionProductInfo {

  &__wrapper {
    margin-top: 100px;
    @media #{$maxW-750} {
      text-align: left;
    }
  }
  &__number {
    @include ralewayBold;
    width: 50px;
    display: inline-block;
    border-radius: 100%;
    background-color: $lightBlue;
    line-height: 50px;
    text-align: center;
    font-weight: bold;
    color: $white;
  }
  &__title {
    @include ralewaySemibold;
    width: calc(100% - 80px);
    display: inline-block;
    vertical-align: text-top;
    padding-left: 30px;
    @media #{$maxW-750} {
      vertical-align: middle;
    }
  }
  &__listWrapper {
    margin-top: 30px;
    padding-left: 50px;
  }
  &__list {
    @media #{$maxW-750} {
      margin-top: 40px;
    }
  }
  .btn {
    margin-top: 40px;
  }
}
