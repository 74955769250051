.btn {
  border-radius: 3px;
  text-align: center;
  transition: color .3s linear, background-color .3s linear;
  @media #{$maxW-750} {
    max-width: 90%;
  }
}

.buttonBlu {
  background-color: $lightBlue;
  border: 1.5px solid $white;
  color: $white;
  &:hover {
    background-color: $white;
    color: $lightBlue;
  }
}

.buttonWhite {
  background-color: $white;
  border: 1.5px solid $lightBlue;
  color: $lightBlue;
  &:hover {
    background-color: $lightBlue;
    color: $white;
  }
}

.buttonFilled {
  background-color: $lightBlue;
  color: $white;
  border: 1.5px solid $lightBlue;
  &:hover {
    background-color: $white;
    color: $lightBlue;
  }
}

.sectionButton {
  @include ralewayMedium;
  width: 220px;
  margin-top: 3.33rem;
  display: inline-block;
  line-height: 50px;
  @media #{$maxW-750} {
    margin-top: 80px;
    text-align: center;
  }
}

.btnPadded {
  @include ralewayRegular;
  padding: .56rem 1.1rem;
  display: inline-block;
}

.btnSmall {
  @include ralewayBold;
  font-size: 14px;
}
