.contacts {
  margin-top: 0;
  &__grid {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    @media #{$maxW-750} {
      margin: auto;
      display: table;
      text-align: left;
    }
  }
  &__item {
    min-width: 0;
    margin-right: 22px;
    flex-basis: auto;
    flex-shrink: 1;
    flex-grow: 1;
    white-space: pre;
    &:last-child {
      padding-right: 0;
    }
  }
  &__icon {
    display: inline-block;
    vertical-align: middle;
    line-height: 30px;
    img {
      display: inline-block;
      vertical-align: middle;
    }
  }
  &__desc {
    padding-left: 20px;
    display: inline-block;
    vertical-align: top;
    line-height: 30px;
    a {
      color: $darkGrey;
      text-decoration: none;
    }
    .contacts__hypertext {
      @include ralewaySemibold;
      text-decoration: underline;
    }
  }
  &__background {
    @include size(100%, auto);
    margin-top: 100px;
  }
  //- special styly pre druhy kontaktov
  &__forms {
    .contacts__item {
      margin-top: 90px;
    }
  }
  //- special styly pre osobny kontakt
  &__personal {
    .contacts__item {
      margin-top: 100px;
      margin-right: 125px;
      flex-grow: 0;
    }
    .contacts__desc {
      @media #{$maxW-750} {
        width: 85%;
        white-space: normal;
      }
      @media #{$maxW-750} {
        width: 80%;
      }
    }
  }
  .name {
    @include ralewayBold;
  }
  .position {
    font-size: 16px;
  }
  .phone {
    margin-top: 45px;
  }
  .phone,
  .email,
  .phone a,
  .email a {
    @include ralewayMedium;
  }
  // end of special styles
}
