// GENERAL */
.section {
  padding: 130px 0;
  &__content {
    vertical-align: middle;
    display: block;
    text-align: center;
  }
  @media #{$maxW-750} {
    padding: 100px 0;
  }
}

// SEKCIA POD SLIDEROM HLAVNYM EET*/
.sectionEET {
  min-height: 520px;
  background: url('../content/images/bluRowBg.jpg') no-repeat center;
  background-size: cover;
  color: $white;
}

// SEKCIA POKLADNY SYSTEM */
.sectionCashRegister {
  padding-bottom: 0;
  position: relative;
  background: $lightBlue;
  color: $white;
  &__bgHolder {
    padding-bottom: 8.89rem;
    position: relative;
    @media #{$maxW-750} {
      padding-bottom: 100px;
    }
  }
  &__bg {
    position: absolute;
    right: 0;
    bottom: 0;
    @media #{$maxW-1024} {
      max-width: 40%;
    }
    @media #{$maxW-750} {
      display: none;
    }
  }
  &__description {
    @include ralewayBold;
    margin-top: 3.33rem;
    font-size: 22px;
    line-height: 40px;
    color: $grey;
    @media #{$maxW-750} {
      margin-top: 60px;
    }
  }
}

// SEKCIA ES SETRI CAS */
.sectionTimeSaver {
  background: url('../content/images/bluRowBg2.jpg') no-repeat center;
  background-size: cover;
  color: $white;
  &__hint {
    margin-top: 30px;
  }
}

// SEKCIA BENEFITY */
.sectionBenefits {
  text-align: center;
  background-color: $lightGrey;
  &__table {
    max-width: 100%;
    margin: 4.12rem auto 0;
    display: table;
    @media #{$maxW-750} {
      margin-top: 60px;
    }
  }
}

// SEKCIA PRESENTATION */
.sectionPresentation {
  background: url('../content/images/row_people_coffee.jpg') no-repeat center;
  background-size: cover;
  color: $white;
  &__desc {
    @include ralewayExtraBold;
    font-size: 30px;
  }
}

// SEKCIA UPGRADE */
.sectionUpgrade {
  text-align: center;
  &__content {
    margin: auto;
    display: table; 
  }
  &__header {
    padding-right: 30px;
    display: table-cell;
    vertical-align: middle;
    white-space: nowrap;
    @media #{$maxW-750} {
      display: block;
    }
  }
  &__img {
    max-width: 100%;
    display: table-cell;
    vertical-align: middle;
    @media #{$maxW-750} {
      margin-top: 60px;
    }
  }
}

// SEKCIA CUSTOMERS */
.sectionCustomers {
  &__header {
    margin-bottom: 15px;
  }
  &__grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    -ms-flex-pack: center;
  }
  &__item {
    padding-top: 3.6rem;
    flex: 0 1 20%;
    @media #{$maxW-750} {
      flex: 0 1 50%;
    }
    @media #{$maxW-460} {
      flex: 0 1 100%;
    }
    img {
      padding-right: 2.22rem;
      max-width:100%;
    }
  }
}

// PODSTRANKA O NAS */
.about {
  &__description {
    margin-top: 50px;
  }
  &__button {
    margin-top: 60px;
  }
}
