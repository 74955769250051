.footer {
  padding: 2.22rem 0;
  &__logo {
    float: left;
    display: flex;
    flex-direction: column;
    small {
      font-size: 14px;
      line-height: 24px;
      @media #{$maxW-750} {
        margin-top: 55px;
      }
    }
    @media #{$maxW-750} {
      display: table;
      margin: 55px auto 0;
      float: none;
    }
  }
  &__logo2 {
    width: 100%;
    float: right;
    img {
      @include size(140px, auto);
      float: right;
      @media #{$maxW-750} {
        display: table;
        margin: 25px auto;
        float: none;
      }
    }
  }
  &__links {
    float: right;
    display: flex;
    flex-direction: row;
    @media #{$maxW-750} {
      width: 100%;
      flex-direction: column;
      float: none;
    }
  }
  &__link {
    line-height: 24px;
    &:not(:last-child) {
      padding-right: 4.45rem;
      @media #{$maxW-750} {
        padding: 0;
      }
    }
    a {
      text-decoration: none;
    }
    @media #{$maxW-750} {
      flex: auto;
    }
  }
  &.grey {
    background-color: $lightGrey;
  }
  &.pushTop {
    margin-top: 130px;
  }
}
