.sectionFaq {
  background-color: $lightGrey;
  @media #{$maxW-750} {
    text-align: left;
  }
  &__header {
    margin-bottom: 0;
  }
  &__wrapper {
    max-width: 970px;
    margin: auto;
  }
  &__question {
    margin-top: 3.33rem;
    font-size: 22px;
    line-height: 34px;
    color: $lightBlue;
    &::after {
      @include size(100%, 2px);
      content: '';
      margin: 10px 0;
      display: block;
      background: url('../content/icons/dot.png') repeat-x;
      background-size: contain;
      @media #{$maxW-750} {
        height: 5px;
      }
    }
    @media #{$maxW-750} {
      margin-top: 50px;
    }
  }
  &__answer {
    padding-left: 2.5rem;
    line-height: 28px;
    @media #{$maxW-750} {
      padding-left: 0;
    }
  }
}
