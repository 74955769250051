@mixin ralewayItalic {
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-style: italic;
}

@mixin ralewayLight {
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
}

@mixin ralewayRegular {
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
}

@mixin ralewayMedium {
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
}

@mixin ralewaySemibold {
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
}

@mixin ralewayBold {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
}
@mixin ralewayExtraBold {
  font-family: 'Raleway', sans-serif;
  font-weight: 800;
}
